/** @jsxImportSource @emotion/react */
import QSModal from '../../../../shared/components/molecules/modal';
import { QSTypography } from '../../../../shared/components/atoms';
import { Box, Button, IconButton } from '@mui/material';
import { css } from '@emotion/react';
import { ReactComponent as Dns } from '../../../../shared/assets/svg/dns.svg';
import { ReactComponent as Contract } from '../../../../shared/assets/svg/contract.svg';
import { ReactComponent as ContractEdit } from '../../../../shared/assets/svg/contract_edit.svg';
import { ReactComponent as Settings } from '../../../../shared/assets/svg/settings.svg';
import { Close } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { showValueFirstUpgradeModalAtom } from '../../../home/v1/states/value-first-modal';
import ValueFirstUpgradeConfirmation from '../value-first-upgrade-confirmation';
import { useState } from 'react';
import { useProfile } from '../../../../shared/hooks/use-profile';
import capitalizeFirstLetter from '../../../../shared/utils/capitalize-first-letter';

export default function ValueFirstUpgradeModalInformation() {
  const userDetails = useProfile();
  const userName = capitalizeFirstLetter(userDetails?.name ?? '');
  const features = [
    { name: 'Faster API calls', icon: <Settings /> },
    { name: 'Quicker template approvals', icon: <Contract /> },
    { name: 'Ability to edit templates', icon: <ContractEdit /> },
  ];
  const lastDate = '25th September 2024';

  const [showValueFirstUpgradeModal, setShowValueFirstUpgradeModal] = useAtom(
    showValueFirstUpgradeModalAtom
  );
  const [
    showValueFirstUpgradeConfirmationModal,
    setShowValueFirstUpgradeConfirmationModal,
  ] = useState(false);

  const handleSlotUpgradeClick = () =>
    setShowValueFirstUpgradeConfirmationModal(true);

  return (
    <div>
      <QSModal
        open={showValueFirstUpgradeModal}
        onClose={() => setShowValueFirstUpgradeModal(false)}
      >
        <Box
          css={css`
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Box
            css={css`
              width: 500px;
              border-radius: 8px;
              gap: 16px;
              background: #ffffff;
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            `}
          >
            <Box
              css={css`
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                position: relative;
              `}
            >
              <Box
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 20px 12px 0px 20px;
                `}
              >
                <Dns />
                <IconButton
                  css={css`
                    position: absolute;
                    top: 12px;
                    right: 8px;
                  `}
                  onClick={() => setShowValueFirstUpgradeModal(false)}
                >
                  <Close
                    css={css`
                      color: #7a7a7a;
                      font-size: 24px;
                    `}
                  />
                </IconButton>
              </Box>
              <QSTypography
                css={css`
                  font-family: Inter;
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 32px;
                  text-align: left;
                  padding: 0px 20px;
                `}
              >
                Mandatory Server Update
              </QSTypography>
              <QSTypography
                css={css`
                  font-family: Inter;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 22.4px;
                  text-align: left;
                  padding: 0px 20px;
                `}
              >
                Hey{' '}
                <span
                  css={css`
                    color: var(--primary-main, #2196f3);
                  `}
                >
                  {userName}
                </span>
                , <br />
                We’ve recently upgraded our internal server to provide you with
                even better and faster services. To complete this update, please
                book a slot using the link below.
              </QSTypography>
            </Box>
            <Box
              css={css`
                border-left: 3px solid #007a5a;
                padding: 16px;
                gap: 8px;
                border-radius: 4px;
                background: #f8f8f8;

                margin: 0px 20px;
              `}
            >
              <QSTypography
                css={css`
                  font-family: Inter;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 22.4px;
                  text-align: left;
                  color: #007a5a;
                `}
              >
                What You’ll Get:
              </QSTypography>
              <Box css={css``}>
                <Box
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 8px;
                  `}
                >
                  {features?.map((feature, index) => (
                    <Box
                      key={index}
                      css={css`
                        display: inline-flex;
                        gap: 8px;
                        align-items: center;
                        height: 36px;
                        padding: 8px;
                        gap: 8px;
                        border-radius: 60px;
                        border: 1px solid #e0e0e9;
                        margin-right: 8px;
                        background: #ffffff;
                      `}
                    >
                      {feature?.icon}
                      <QSTypography
                        css={css`
                          font-family: Inter;
                          font-size: 12px;
                          font-weight: 500;
                          line-height: 14.4px;
                          text-align: left;
                        `}
                      >
                        {feature.name}
                      </QSTypography>
                    </Box>
                  ))}
                </Box>
                <Box css={css`display: flex; justify-content flex-end;`}>
                  <QSTypography
                    css={css`
                      font-family: Inter;
                      font-size: 12px;
                      font-weight: 500;
                      line-height: 19.2px;
                      text-align: right;
                      width: 100%;
                      color: #7a7a7a;
                    `}
                  >
                    …and many more exciting features!
                  </QSTypography>
                </Box>
              </Box>
            </Box>
            <Box
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 0px 20px;
              `}
            >
              <Box>
                <QSTypography
                  css={css`
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 22.4px;
                    text-align: left;
                    color: #2d2d2d;
                  `}
                >
                  Important:
                </QSTypography>
              </Box>
              <Box>
                <QSTypography
                  css={css`
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 19.2px;
                    text-align: left;
                    color: #7a7a7a;
                  `}
                >
                  This update is mandatory. Please book your slot and complete
                  the upgrade by {' '}
                  <span
                    css={css`
                      color: #d32f2f;
                      font-weight: 600;
                    `}
                  >
                    {lastDate}
                  </span>
                   to ensure your DoubleTick services continue to run smoothly.
                </QSTypography>
              </Box>
            </Box>
            <Box
              css={css`
                padding: 0px 24px 20px 24px;
              `}
            >
              <Button
                fullWidth
                variant="contained"
                css={css`
                  font-size: 14px;
                  font-weight: 600;
                `}
                onClick={handleSlotUpgradeClick}
              >
                Book a slot to update
              </Button>
            </Box>
          </Box>
        </Box>
      </QSModal>
      <ValueFirstUpgradeConfirmation
        showValueFirstUpgradeConfirmationModal={
          showValueFirstUpgradeConfirmationModal
        }
        setShowValueFirstUpgradeConfirmationModal={
          setShowValueFirstUpgradeConfirmationModal
        }
      />
    </div>
  );
}
